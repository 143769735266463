import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { GridsterItem } from 'angular-gridster2'
import { cloneDeep, isEqual, some } from 'lodash-es'
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators'
import { first } from 'rxjs/operators'

import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit,
  SimpleChanges, ViewChild
} from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'

import { Actions, ofType } from '@ngrx/effects'
import {
  AddGridsterChartToUpdate, DisableGridsterEditMode, EnableGridsterEditMode,
  PersistGridsterCharts, ResetGridsterDashboard, RollbackAllGridsterCharts
} from '../actions/gridster-dashboard.actions'
import { AppConfigService } from '../../wall/services/app-config.service'
import { CallApi } from '../../shared/actions/api.actions'
import { ChartFilters } from '../models/chart-filters'
import { ChartFiltersComponent } from './chart-filters/chart-filters.component'
import {
  ChartsInitialConfig,
  LoadDashboardTabPayload,
  LoadSharedDashboardTabFailure,
  LoadSharedDashboardTabPayload,
  LoaderActionTypes,
  UpdateSharedUser
} from '../../core/actions/loader.actions'
import { ClipboardService } from 'ngx-clipboard'
import { CustomDashboardService } from '../services/custom-dashboard.service'
import {
  DashboardActionTypes,
  DashboardSaveSharedTab,
  DashboardSaveSharedTabSuccess,
  DashboardSetFilters,
  RemoveDashboardTab
} from '../actions/filters.actions'
import { DashboardChart } from '../models/dashboard-chart'
import { DashboardFormComponent } from './dashboard-form/dashboard-form.component'
import { DashboardPdfService } from '../services/dashboard-pdf.service'
import { DashboardTab, DashboardTabWithCharts } from '../models/dashboard-tab'
import { DashboardTypes } from './dashboard-types'
import { DefaultColorScheme } from './color-schemes'
import { GenericTableModalActionTypes } from '../../core/actions/generic-table.actions'
import { Grid } from '../models/gridster/grid'
import { LINE_CHART_TYPES } from './line-chart.component'
import { Router } from '@angular/router'
import {
  ScheduleNewReportModalComponent
} from '../../user-settings/scheduled-reports-page/schedule-new-report-modal/schedule-new-report-modal.component'
import { SegmentService } from '../../core/services/segment.service'
import { SharedTab, TabActionTypes } from '../../wall/actions/tabs.actions'
import { SlackModalService } from '../../shared/services/slack-modal.service'
import { TabShareComponent } from '../../shared/components/tab-share.component'
import { ToastrService } from 'ngx-toastr'
import { User } from '../../wall/models/user'
import { isApiLoaded } from '../../shared/state/selectors'
import { objKeysSafe, present } from '../../shared/utils/general-utils'
import { observableImmediatelySync, toasterOnAction, toasterOnActionPromise } from '../../shared/utils/store.utils'
import { selectChartsInitConfig, selectCustomDashboardOverrideFilters, selectGridsterEditMode } from '../reducers'

const CUSTOM_DASHBOARD_API = 'custom_dashboard_tab.'

@Component({
  selector: 'twng-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  styleUrls: [
    './dashboard.layout.scss',
    './edit-mode.component.scss',

    './dashboard.shared.component.scss',
    './dashboard.component.scss',

    './gridster.component.scss',
    './dashboard.gridster.component.scss',
    './custom-dashboard.component.scss',
  ]
})
export class CustomDashboardComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('gridsterContainer', { static: false }) gridsterContainer: ElementRef
  @ViewChild('chartFilters') childChartFilters: ChartFiltersComponent;

  @Input()
    tab: DashboardTabWithCharts

  @Input()
    exporting: boolean

  @Input()
    user: User

  canViewDashboard: boolean

  lineChartTypes = LINE_CHART_TYPES

  grid: Grid
  gridsterEditModeEnabled$: Observable<boolean>
  exportingPdf$: Observable<boolean>
  defaultColorScheme = DefaultColorScheme
  demoMode = !!window.twng_demo

  charts$ = new BehaviorSubject<DashboardChart[]>(null)
  chartFilters$: Observable<ChartFilters>
  chartFilters: ChartFilters
  chartFiltersEnabled = true
  customDashboard = DashboardTypes.CustomDashboard
  isOverridingFilters = false
  chartInitConfig: ChartsInitialConfig

  loaded$: Observable<boolean>
  private myApiName: string

  private subscriptions = new Subscription()

  @Input()
  // this will be null when not viewing shared dashboard
    sharedDashboardToken: string
  isSavingSharedTab = false

  constructor(
    private segmentService: SegmentService,
    public dashboardPdfService: DashboardPdfService,
    private store: Store,
    private cd: ChangeDetectorRef,
    public appConfig: AppConfigService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private actions: Actions,
    private customDashboardService: CustomDashboardService,
    private clipboardService: ClipboardService,
    private router: Router,
    private slackModal: SlackModalService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.actions.pipe(
      ofType(
        LoaderActionTypes.LoadSharedDashboardTabFailure
      ),
      first(),
    ).subscribe((action: LoadSharedDashboardTabFailure) => {
      if (action.payload.status === 404) {
        this.router.navigate(['shared-dashboard/not_found'])
      }
    }))

    this.initGridster()

    this.canViewDashboard = this.appConfig.config.feature_flags.dashboard.valueOf()

    this.gridsterEditModeEnabled$ = this.store.select(selectGridsterEditMode).pipe(
      // make sure we never enter edit mode when we are viewing shared dashboard
      map(value => value && !this.sharedDashboardToken),
      distinctUntilChanged(),
      tap((gridsterEditModeEnabled) => {
        if (gridsterEditModeEnabled) {
          this.gridsterContainer?.nativeElement.childNodes[0].classList.add('scrollVertical')
          this.grid.enableEditMode()
        } else {
          this.gridsterContainer?.nativeElement.childNodes[0].classList.remove('scrollVertical')
          this.grid.disableEditMode()
        }
        this.cd.detectChanges()
      }),
      shareReplay(1)
    )

    this.chartFilters$ = this.store.select(selectCustomDashboardOverrideFilters)
      .pipe(
        map(filters => {
          const emptyFilters: ChartFilters = {
            candidate_custom_fields: [],
            custom_fields: [],
            date_mode: 'custom',
            department_ids: [],
            end_date: null,
            hiring_manager_ids: [],
            job_external_ids: [],
            offer_custom_fields: [],
            office_ids: [],
            source_ids: [],
            start_date: null,
            user_ids: [],
            sourcing_user_external_ids: [],
            candidate_tags: [],
            candidate_recruiter_ids: [],
            candidate_coordinator_ids: [],
            credited_to_ids: [],
            job_primary_recruiter_external_ids: [],
            job_coordinator_external_ids: [],
            job_sourcer_external_ids: []
          }
          return filters || emptyFilters
        }),
        tap(filters => this.chartFilters = filters)
      )
    this.chartFilters = observableImmediatelySync(this.chartFilters$)

    this.myApiName = CUSTOM_DASHBOARD_API + this.tab.id
    this.loaded$ = isApiLoaded(this.store, this.myApiName)
    this.subscriptions.add(this.chartFilters$.pipe(
      distinctUntilChanged(isEqual)
    ).subscribe(() => {
      this.doLoadData(true)
    }))
    this.subscriptions.add(this.actions.pipe(
      ofType(GenericTableModalActionTypes.DataChangedInModal)
    ).subscribe(() => {
      this.handleDataChangedFromGenericTable()
    }))

    this.subscriptions.add(this.store.select(selectChartsInitConfig).pipe(
      tap(state => this.chartInitConfig = state)
    ).subscribe())

    this.subscriptions.add(this.loaded$.subscribe({
      next: (next) => {
        if (next) {
          setTimeout(() => {
            this.gridsterContainer.nativeElement.childNodes[0].classList.remove('scrollVertical')
          }, 100)
        }
      }
    }))
  }

  getScrollHeight() {
    return this.exporting ? `${this.gridsterContainer.nativeElement.childNodes[0].scrollHeight + 100}px` : ''
  }

  private handleDataChangedFromGenericTable() {
    this.doLoadData(true)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tab) {
      this.charts$.next(this.tab.charts)
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  exportToPDF(sectionId: string) {
    this.exporting = true
    this.cd.markForCheck()
    if (sectionId.startsWith("dashboard-")) {
      this.dashboardPdfService.exportToPDF(`#${sectionId}`, { tabName: this.tab.name })
        .catch((err) => console.error(err))
    }
  }

  enableEditMode() {
    this.segmentService.track("Dashboard Edit Layout")
    this.store.dispatch(new EnableGridsterEditMode())
  }

  cancelEditMode() {
    this.segmentService.track("Dashboard Cancel Edit Layout")
    this.store.dispatch(new RollbackAllGridsterCharts())
    this.store.dispatch(new DisableGridsterEditMode())

    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.router.onSameUrlNavigation = 'reload'
    this.router.navigate([`/dashboard/dashboards/${this.tab.id}`])
  }

  saveGridster() {
    this.segmentService.track("Dashboard Save Layout")
    this.store.dispatch(new PersistGridsterCharts())
    this.store.dispatch(new DisableGridsterEditMode())
    this.gridsterContainer?.nativeElement.childNodes[0].classList.remove('scrollVertical')
  }

  initGridster() {
    this.store.dispatch(new ResetGridsterDashboard())
    this.grid = new Grid()
    this.grid.onItemChange((gridsterItem: GridsterItem) => {
      // Remove initCallback before dispatching to store
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { initCallback, ...gridsterItemRest } = gridsterItem
      this.store.dispatch(new AddGridsterChartToUpdate({ gridsterItem: gridsterItemRest }))
    })
  }

  editTabModal(tab: DashboardTab) {
    const modalRef = this.modalService.open(DashboardFormComponent)
    modalRef.componentInstance.setEditingTab(tab)
  }

  deleteTab(tab: DashboardTab) {
    this.store.dispatch(new RemoveDashboardTab({ tab }))
    this.segmentService.track("Delete Dashboard Tab")
    this.updateUserDashboards(tab)
  }

  updateUserDashboards(newDash) {
    const userClone = cloneDeep(this.user)
    const index = userClone.dashboard_tabs.findIndex(dash => dash.id === newDash.id)
    if (index > -1) {
      userClone.dashboard_tabs.splice(index, 1)
      this.store.dispatch(new UpdateSharedUser(userClone))
    }
  }

  async duplicateTab() {
    this.store.dispatch(new SharedTab({
      type: 'dashboard',
      activeTab: this.tab.editable ? this.tab.saved_as_editable_from_tab_id : this.tab.id,
      user_ids: [this.user.id],
      filters: this.chartFilters,
      sharedWithUsers: []
    }))

    this.actions.pipe(
      ofType(TabActionTypes.SharedTabSuccess),
      first(),
    ).subscribe(({payload: {current_user_new_tab_id}}) => {
      this.router.navigate([`/dashboard/dashboards/${current_user_new_tab_id}`]).then(() => {
        window.location.reload()
      })
    })
  }

  trackChartById(_index: number, chart: DashboardChart) {
    return chart ? chart.id : null
  }

  openShareTab() {
    const tabShareModalRef = this.modalService.open(
      TabShareComponent,
    )
    const modalInstance = tabShareModalRef.componentInstance as TabShareComponent
    modalInstance.tabType = 'dashboard'
    modalInstance.selectedTabId = this.tab.id
    modalInstance.filterUsersCanViewDashboard = true
    modalInstance.overrideFilters = this.overrideFiltersSet() ? this.chartFilters : undefined
  }

  openSendToSlackTab() {
    this.slackModal.openSendToSlackTab(this.getSharableLink(false).then(
      link => "Check out my Talentwall Analytics Dashboard " + link
    ))
  }

  async scheduleDashboardSending() {
    const token = this.overrideFiltersSet() ? await this.getSharableToken(false) : undefined
    const modal = this.modalService.open(ScheduleNewReportModalComponent)
    const component = modal.componentInstance as ScheduleNewReportModalComponent
    component.scheduleDashboardTab(this.tab, token)
  }

  private getSharableToken(editable: boolean) {
    return this.customDashboardService.createNewSharableToken(this.tab.id.toString(), this.chartFilters, editable)
  }

  private async getSharableLink(editable: boolean) {
    const token = await this.getSharableToken(editable)
    return `${new URL(document.URL).protocol}//${window.location.host}/shared-dashboard/${this.tab.id}/${token}`
  }

  async copyShareableLinkToClipboard(editable: boolean) {
    try {
      this.clipboardService.copy(await this.getSharableLink(editable))
      this.toastr.success(`Copied to clipboard`)
    } catch {
      this.toastr.error("Error creating link")
    }
  }

  private async dispatchChangeFilters(filters: Partial<ChartFilters> | null) {
    this.isOverridingFilters = true
    this.store.dispatch(new DashboardSetFilters({
      panel: DashboardTypes.CustomDashboard,
      filters
    }))
    await toasterOnActionPromise(
      [LoaderActionTypes.DashboardLoadSuccess],
      [DashboardActionTypes.DashboardSetFiltersError],
      null, "Error changing filters",
      this.toastr, this.actions,
    )
    this.isOverridingFilters = false
  }

  onTempFiltersChanged(filters: Partial<ChartFilters>) {
    this.dispatchChangeFilters(filters)
  }

  resetChartFilters() {
    this.childChartFilters.closeFilters()
    this.dispatchChangeFilters(null)
  }

  overrideFiltersSet(): boolean {
    const cf = this.chartFilters
    if (cf) {
      const dateChanged = (
        !!cf.start_date ||
        !!cf.end_date ||
        cf.date_mode !== 'custom'
      )
      const somethingElseChanged = some(objKeysSafe(cf), (key: keyof ChartFilters) =>
        key !== 'start_date' && key !== 'end_date' && key !== 'date_mode' && present(cf[key])
      )
      return dateChanged || somethingElseChanged
    } else {
      return false
    }
  }

  private doLoadData(forceReload: boolean) {
    if (!this.sharedDashboardToken) {
      const payload: LoadDashboardTabPayload = {
        tabId: this.tab.id
      }
      this.store.dispatch(
        new CallApi({
          doAlways: forceReload,
          apiName: this.myApiName,
          startAction: {
            payload,
            type: LoaderActionTypes.LoadDashboardTab
          },
          failureAction: LoaderActionTypes.LoadDashboardTabFailure,
          successAction: LoaderActionTypes.LoadDashboardTabSuccess,
        })
      )
    } else {
      const payload: LoadSharedDashboardTabPayload = {
        tabId: this.tab.id.toString(),
        sharable_token: this.tab.sharable_token,
      }
      this.store.dispatch(
        new CallApi({
          doAlways: forceReload,
          apiName: this.myApiName,
          startAction: {
            payload,
            type: LoaderActionTypes.LoadSharedDashboardTab
          },
          failureAction: LoaderActionTypes.LoadSharedDashboardTabFailure,
          successAction: LoaderActionTypes.LoadSharedDashboardTabSuccess,
        })
      )
    }
  }

  async saveSharedDashboard(name: string, viewEditable: boolean) {
    if (this.sharedDashboardToken) {
      this.isSavingSharedTab = true
      this.store.dispatch(new DashboardSaveSharedTab({
        name,
        viewEditable,
        id: this.tab.id,
        sharableToken: this.sharedDashboardToken,
      }))
      try {
        const ret = await toasterOnAction(
          [DashboardActionTypes.DashboardSaveSharedTabSuccess],
          [DashboardActionTypes.DashboardSaveSharedTabFailure],
          null, "Error saving tab", this.toastr, this.actions,
        ) as DashboardSaveSharedTabSuccess
        // if api call returned success
        if (ret) {
          this.router.navigate(['dashboard/dashboards/' + ret.payload.dashboard_tab.id ])
        }
      } catch(err) {
        throw err
      } finally {
        this.isSavingSharedTab = false
        this.cd.detectChanges()
      }
    }
  }
}
